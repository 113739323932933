import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Input as AInput, Row } from 'antd';
import { TrackingInput } from 'components/TrackingComponents';
import styled from 'styled-components';
import { get, keys } from 'lodash';
import messages, { toCityId, toDistrictId } from './messages';
import { FormInput } from '../Form';
import SSelect from '../Select';
import enLocations from 'translations/en/locations';
import translations from 'translations';

const Input = styled(AInput)<any>``;
const Select = styled(SSelect)`
  min-width: auto;
`;

const TRACKING_ACTION_CATEGORY = 'Edit Location Form | Input';

interface LocationT {
  address: string;
  city: string;
  district: string;
}

const LocationFormSection: React.FC<{
  location: LocationT;
  formPrefix: string[];
  form: any;
}> = ({ location, formPrefix, form }) => {
  const [districtList, setDistrictList] = useState<{ value: string; label: string }[]>([]);
  const cities = keys(enLocations).map((city) => ({
    value: city,
    label: translations(toCityId(city)),
  }));
  const resetDistrict = (value) => {
    const districtValue = [...formPrefix, 'district'].reverse().reduce((acc: any, key) => ({ [key]: acc }), '');
    form.setFieldsValue(districtValue);
    const newDistrictList = keys(get(enLocations, `${value}.districts`, {})).map((district) => ({
      value: district,
      label: translations(toDistrictId(value, district)),
    }));
    setDistrictList(newDistrictList ?? []);
  };
  useEffect(() => {
    const cityValue = form.getFieldValue(formPrefix)?.city;
    if (cityValue) {
      const newDistrictList = keys(get(enLocations, `${cityValue}.districts`, {})).map((district) => ({
        value: district,
        label: translations(toDistrictId(cityValue, district)),
      }));
      setDistrictList(newDistrictList ?? []);
    }
  }, []);
  return (
    <Row gutter={8}>
      <Col lg={12} xs={24}>
        <FormattedMessage {...messages.address}>
          {(address: string) => (
            <FormInput
              type={{ type: 'required', field: address }}
              formId={[...formPrefix, 'address']}
              label={address}
              initialValue={location.address}
            >
              <TrackingInput
                options={{
                  category: TRACKING_ACTION_CATEGORY,
                  action: 'Enter location address',
                }}
              >
                <Input type={'text'} />
              </TrackingInput>
            </FormInput>
          )}
        </FormattedMessage>
      </Col>
      <Col lg={6} xs={24}>
        <FormattedMessage {...messages.city}>
          {(city: string) => (
            <FormInput
              type={{ type: 'required', field: city }}
              formId={[...formPrefix, 'city']}
              label={city}
              initialValue={location.city}
            >
              <Select items={cities} onChange={resetDistrict} />
            </FormInput>
          )}
        </FormattedMessage>
      </Col>
      <Col lg={6} xs={24}>
        <FormattedMessage {...messages.district}>
          {(district: string) => (
            <FormInput
              type={{ type: 'required', field: district }}
              formId={[...formPrefix, 'district']}
              label={district}
              initialValue={location.district}
            >
              <Select items={districtList} />
            </FormInput>
          )}
        </FormattedMessage>
      </Col>
    </Row>
  );
};

export default LocationFormSection;
