/*
 *
 * CompanyInfoPage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/CompanyInfoPage/DEFAULT_ACTION',
  COMPANY_INFO_SUBMIT = 'app/CompanyInfoPage/COMPANY_INFO_SUBMIT',
  COMPANY_INFO_REQUEST = 'app/CompanyInfoPage/COMPANY_INFO_REQUEST',
  COMPANY_INFO_SUCCESS = 'app/CompanyInfoPage/COMPANY_INFO_SUCCESS',
  COMPANY_INFO_FAILURE = 'app/CompanyInfoPage/COMPANY_INFO_FAILURE',
  SET_FEATURE_FLAG = 'app/CompanyInfoPage/SET_FEATURE_FLAG',
}

export default ActionTypes;
