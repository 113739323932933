import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the companyInfoPage state domain
 */

const selectCompanyInfoPageDomain = (state: ApplicationRootState) => {
  return state ? state : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by CompanyInfoPage
 */

const selectCompanyInfoPage = () =>
  createSelector(selectCompanyInfoPageDomain, substate => {
    return substate;
  });

export default selectCompanyInfoPage;
export { selectCompanyInfoPageDomain };
