export const signImageUpload = (data: { resourceGroup: string; contentType: string; fileName: string }) => {
  const body = {
    variables: `${JSON.stringify(data)}`,
    query: `
      mutation signImageUpload(
        $resourceGroup: ResourceGroup!
        $contentType: String!
        $fileName: String!
      ) {
        signImageUpload(
          resourceGroup: $resourceGroup
          contentType: $contentType
          fileName: $fileName
        ) {
          success
          userErrors {
            field
            message
          }
          uploadUrl
          fullUrl
        }
      }
    `,
  };

  const apiUrl = process.env.API_URL || 'https://buyer-graphql.qa.kamereo.vn';

  const headers = {
    'Content-Type': 'application/json',
    'Accept-Language': window.localStorage.getItem('lang') || 'vi',
  };
  const token = localStorage.getItem('token');
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return fetch(apiUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers,
  })
    .then((response: any) => {
      return response.json();
    })
    .then((data: any) => {
      return {
        ...data.data['signImageUpload'],
      };
    })
    .catch((error: any) => {
      return {
        errors: [error],
      };
    });
};
