import { call, fork, put, take } from 'redux-saga/effects';
import { setFeatureFlag, updateCompany } from 'utils/apollo';

import ActionTypes from './constants';
import { buyer as buyerActions, updateEnabledFeatures } from 'containers/MainLayout/actions';
import { companyInfo as companyInfoActions } from './actions';
import { message } from 'antd';
import translations from 'translations';
import utilsMessages from 'utils/messages';

function* companyInfoFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.COMPANY_INFO_SUBMIT);
    yield put(companyInfoActions.request());
    const redInvoice = payload.redInvoiceInfo;
    delete payload.redInvoiceInfo;
    const response = yield call(updateCompany, {
      buyer: payload,
      redInvoice: redInvoice,
    });
    if (!response.errors) {
      yield put(companyInfoActions.success({ ...response, ...payload, redInvoiceInfo: { ...redInvoice } }));
      yield put(buyerActions.success({ ...response, ...payload, redInvoiceInfo: { ...redInvoice } }));
    } else {
      yield put(companyInfoActions.failure(response.errors));
    }
  }
}

function* setFeatureFlagFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.SET_FEATURE_FLAG);
    const response = yield call(setFeatureFlag, {
      input: {
        ...payload,
        featureFlag: 'ORDER_APPROVAL',
      },
    });
    if (!response.errors) {
      message.success(translations(utilsMessages.saveSuccess));
      yield put(updateEnabledFeatures({ feature: 'ORDER_APPROVAL', enabled: payload.enabled }));
    } else {
      message.error(translations(utilsMessages.saveFailed));
    }
  }
}

// Individual exports for testing
export default function* companyInfoPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(companyInfoFlow);
  yield fork(setFeatureFlagFlow);
}
