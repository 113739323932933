/*
 *
 * CompanyInfoPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const setFeatureFlag = data => action(ActionTypes.SET_FEATURE_FLAG, data);

export const companyInfoSubmit = (data: any) => action(ActionTypes.COMPANY_INFO_SUBMIT, data);
export const companyInfo = createAsyncAction(
  ActionTypes.COMPANY_INFO_REQUEST,
  ActionTypes.COMPANY_INFO_SUCCESS,
  ActionTypes.COMPANY_INFO_FAILURE,
)<void, {}, Error>();
