import * as React from 'react';
import styled from 'styled-components';
import LazyImage from 'components/Image/LazyImage';
import SVGIcon from '../SVGIcon/SVGIcon';
import noImage from './noIMG.svg';

const Image = styled(LazyImage)`
  width: 100%;
  display: block;
`;

const NoImage = styled.div<any>`
  background: #e8ebf2;
  padding: ${({ nopadding }) => (nopadding ? '' : '72pt')};
  border-radius: 8px;
`;

const ImageContainer = styled.div<any>`
  overflow: hidden;
  ${({ zoomIn }) =>
    zoomIn
      ? `
    img {
      transition: transform 0.3s ease-out;
      transform: scale(1, 1, 1);
      &:hover {
        transform: scale3d(1.2, 1.2, 1.2);
      }
    }
  `
      : ``};

  ${({ height, width }) => `
    ${Image} {
      width: ${width};
      height: ${height};
    }
    ${NoImage} {
      svg {
        width: ${width};
        height: ${height};
      }
    }
  `};
`;

export default function ({
  src,
  nopadding,
  height,
  width,
  zoomIn,
  ...rest
}: {
  src?: string;
  nopadding?: boolean;
  height?: string;
  width?: string;
  zoomIn?: boolean;
  responsive?: boolean;
  alt?: string;
}) {
  return (
    <ImageContainer zoomIn={zoomIn} height={height} width={width}>
      {src ? (
        <Image src={src} width={width} height={height} {...rest} />
      ) : (
        <NoImage nopadding={nopadding}>
          <SVGIcon src={noImage} width={width} height={height} />
        </NoImage>
      )}
    </ImageContainer>
  );
}
