import styled from 'styled-components';

export const Box = styled.div<{ loading?: boolean }>`
  background: #fcfffc;
  border: none;
  border-radius: 4px;
  padding: 8pt;
  margin-bottom: 6pt;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  ${({ loading }) => {
    if (loading) {
      return `
        pointer-events: none;
        cursor: default;
      `;
    }
    return `
      pointer-events: auto;
    `;
  }};
`;
