import React, { ReactElement, ReactNode } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { match } from '../../../utils/taggedUnions';

export type FieldType =
  | {
      type: 'optional';
    }
  | {
      type: 'required';
      field: string;
    };

interface Props {
  type: FieldType;
  formId: string[] | string;
  label: ReactNode;
  initialValue?: any;
  children: ReactElement;
}

export const FormInput: React.FC<Props> = ({ type, formId, label, initialValue, children }): ReactElement => {
  return match(type, {
    required: ({ field }) => (
      <FormattedMessage {...messages.required} values={{ field: field }}>
        {(requiredMessage) => (
          <Form.Item
            name={formId}
            label={label}
            colon={false}
            rules={[
              {
                required: true,
                message: requiredMessage,
              },
            ]}
            initialValue={initialValue}
          >
            {children}
          </Form.Item>
        )}
      </FormattedMessage>
    ),
    optional: () => (
      <Form.Item name={formId} label={label} colon={false} initialValue={initialValue}>
        {children}
      </Form.Item>
    ),
  });
};

export default FormInput;
