/**
 *
 * CompanyInfoPage
 *
 */

import React, { Fragment } from 'react';
import { Dispatch, compose } from 'redux';
import { companyInfoSubmit, setFeatureFlag as setFeatureFlagAction } from './actions';

import Company from 'components/Company/Company';
import { push } from 'connected-react-router';
import { selectBuyer, selectCountPendingOrder, selectUserPermissions } from 'containers/MainLayout/selectors';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import translations from 'translations';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import selectCompanyInfoPage from './selectors';
import { RootState } from './types';

interface OwnProps {
  buyer: any;
  permissions: any;
  countPending: number;
}

interface StateProps {}

interface DispatchProps {
  onUpdateCompanyInfo: (data: any) => void;
  onSetFeatureFlag: (data: any) => void;
  goToPendingPage: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

export class CompanyInfoPage extends React.PureComponent<Props> {
  public render() {
    const { buyer, permissions, countPending, goToPendingPage } = this.props;
    return (
      <Fragment>
        <Helmet>
          <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
          <meta name="description" content={`${translations(messages.header)}`} />
        </Helmet>
        <Company
          goToPendingPage={goToPendingPage}
          company={buyer}
          countPending={countPending}
          onUpdateCompanyInfo={this.props.onUpdateCompanyInfo}
          onSetFeatureFlag={this.props.onSetFeatureFlag}
          permissions={permissions}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  companyinfopage: selectCompanyInfoPage(),
  buyer: selectBuyer(),
  permissions: selectUserPermissions(),
  countPending: selectCountPendingOrder(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onUpdateCompanyInfo: (data: any) => dispatch(companyInfoSubmit(data)),
    onSetFeatureFlag: (data: any) => dispatch(setFeatureFlagAction(data)),
    goToPendingPage: () => dispatch(push('/orders-pending-approval')),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'companyInfoPage',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'companyInfoPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(CompanyInfoPage);
