/*
 * LocationFormSection Messages
 *
 * This contains all the text for the LocationFormSection component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.LocationFormSection';

export const toCityId = (city) => ({
  id: `location.${city}.name`,
});

export const toDistrictId = (city, district) => ({
  id: `location.${city}.districts.${district}.name`,
});
export const toWardId = (city, district, ward) => ({
  id: `location.${city}.districts.${district}.wards.${ward}`,
});

export default defineMessages({
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Điạ chỉ',
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: 'Thành phố',
  },
  district: {
    id: `${scope}.district`,
    defaultMessage: 'Quận',
  },
});
