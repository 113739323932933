import React, { useImperativeHandle } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Input as AInput, Row, Form } from 'antd';
import styled from 'styled-components';
import messages from 'containers/CompanyInfoPage/messages';
import TrackingButton from 'components/TrackingComponents/Button';
import { TrackingInput } from '../TrackingComponents';
import LocationFormSection from '../LocationFormSection';

const Input = styled(AInput)<any>``;

const InfoContainer = styled.div`
  margin-bottom: 6pt;
`;

const Heading = styled.h4`
  margin-bottom: 6pt;
`;

const CompanyFormContainer: React.ForwardRefRenderFunction<
  any,
  {
    company: any;
    onSubmit: (data: any) => void;
    onCancel: () => void;
  }
> = ({ company, onSubmit, onCancel }, ref) => {
  const [form] = Form.useForm();
  const formRef = React.useRef();
  useImperativeHandle(ref, () => formRef.current!);

  const _onCancel: React.MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    onCancel();
  };
  const locationValue = Form.useWatch('location', form);
  const redInvoiceInfoValue = Form.useWatch('redInvoiceInfo', form);

  const { getFieldValue } = form;
  return (
    <Form form={form} layout="vertical" requiredMark={false} onFinish={(values) => onSubmit(values)} size="large">
      <InfoContainer>
        <Heading>
          <FormattedMessage {...messages.basicInfo} />
        </Heading>
        <Row gutter={8}>
          <Col lg={24} xs={24}>
            <FormattedMessage {...messages.companyName}>
              {(companyName) => (
                <FormattedMessage {...messages.companyInfoRequired} values={{ field: companyName }}>
                  {(companyInfoRequired) => (
                    <Form.Item
                      name="name"
                      label={companyName}
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: companyInfoRequired,
                        },
                      ]}
                      initialValue={getFieldValue('name') || company.name}
                    >
                      <TrackingInput
                        options={{
                          category: 'Edit Company Form | Input',
                          action: 'Enter Company Name',
                        }}
                      >
                        <Input type={'input'} />
                      </TrackingInput>
                    </Form.Item>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
        </Row>
        <LocationFormSection
          location={{
            address: locationValue?.address || company.location.address,
            city: locationValue?.city || company.location.city,
            district: locationValue?.district || company.location.district,
          }}
          formPrefix={['location']}
          form={form}
        />
        <Row gutter={8}>
          <Col lg={24} xs={24}>
            <FormattedMessage {...messages.phone}>
              {(phone) => (
                <FormattedMessage {...messages.companyInfoRequired} values={{ field: phone }}>
                  {(companyInfoRequired) => (
                    <Form.Item
                      name="telephone"
                      label={phone}
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: companyInfoRequired,
                        },
                      ]}
                      initialValue={company.telephone}
                    >
                      <TrackingInput
                        options={{
                          category: 'Edit Company Form | Input',
                          action: 'Enter Company Phone',
                        }}
                      >
                        <Input type={'input'} />
                      </TrackingInput>
                    </Form.Item>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
        </Row>
      </InfoContainer>
      <InfoContainer>
        <Heading>
          <FormattedMessage {...messages.redInvoiceInfo} />
        </Heading>
        <Row gutter={8}>
          <Col lg={24} xs={24}>
            <FormattedMessage {...messages.companyName}>
              {(companyName) => (
                <FormattedMessage {...messages.companyInfoRequired} values={{ field: companyName }}>
                  {(companyInfoRequired) => (
                    <Form.Item
                      name={['redInvoiceInfo', 'companyName']}
                      label={companyName}
                      colon={false}
                      rules={[{ required: true, message: companyInfoRequired }]}
                      initialValue={company.redInvoiceInfo.companyName}
                    >
                      <TrackingInput
                        options={{
                          category: 'Edit Company Form | Input',
                          action: 'Enter Company Red Invoice Company Name',
                        }}
                      >
                        <Input type={'input'} />
                      </TrackingInput>
                    </Form.Item>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
        </Row>
        <LocationFormSection
          location={{
            address: redInvoiceInfoValue?.location?.address || company.redInvoiceInfo.location.address,
            city: redInvoiceInfoValue?.location?.city || company.redInvoiceInfo.location.city,
            district: redInvoiceInfoValue?.location?.district || company.redInvoiceInfo.location.district,
          }}
          formPrefix={['redInvoiceInfo', 'location']}
          form={form}
        />
        <Row gutter={8}>
          <Col lg={24} xs={24}>
            <FormattedMessage {...messages.taxCode}>
              {(taxCode) => (
                <FormattedMessage {...messages.companyInfoRequired} values={{ field: taxCode }}>
                  {(companyInfoRequired) => (
                    <Form.Item
                      name={['redInvoiceInfo', 'mst']}
                      label={taxCode}
                      colon={false}
                      rules={[{ required: true, message: companyInfoRequired }]}
                      initialValue={company.redInvoiceInfo.mst}
                    >
                      <TrackingInput
                        options={{
                          category: 'Edit Company Form | Input',
                          action: 'Enter Company Red Invoice Tax Code',
                        }}
                      >
                        <Input type={'input'} />
                      </TrackingInput>
                    </Form.Item>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
        </Row>
      </InfoContainer>
      <Row>
        <Col offset={8} span={8}>
          <TrackingButton
            trackingCategory="Edit Company Form | Button"
            trackingAction="Cancel"
            block
            type="secondary"
            ghost
            onClick={_onCancel}
          >
            <FormattedMessage {...messages.cancel} />
          </TrackingButton>
        </Col>
        <Col span={8}>
          <TrackingButton
            trackingCategory="Edit Company Form | Button"
            trackingAction="Save"
            block
            type="primary"
            htmlType="submit"
          >
            <FormattedMessage {...messages.save} />
          </TrackingButton>
        </Col>
      </Row>
    </Form>
  );
};

export default React.forwardRef(CompanyFormContainer);
