import { Form as AForm } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import styled, { css } from 'styled-components';
import media from 'utils/mediaStyle';

/**
 * @deprecated
 * Please use the followings instead.
 *
 * import { FormItem } from 'component/Form'
 *
 */
export const Item = AForm.Item;

const Form = styled(AForm)<any>`
  ${({ layout }) => {
    if (layout === 'inline') {
      return css`
        .ant-form-item-control-wrapper {
          width: 100%;
        }
        display: flex;
        flex-direction: column;
        .ant-form-item {
          margin-right: 0 !important;
          margin-bottom: 10pt !important;
        }
        ${media.lg`
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          .ant-form-item {
            margin-right: 16px !important;
            margin-bottom: 0 !important;
          }
          button {
            margin-top: 24px 0 2px;
          }
        `};
        button {
          margin-top: 12px;
        }
      `;
    }
    return ``;
  }};
` as any;

Form.create = AForm.create;

export default Form;
