/**
 * Pattern matching utility function for tagged unions with `type` property as a tag.
 *
 * @param x - A tagged union, which has `type` property as a string tag
 * @param patterns - Functions to derive values for each variant. Property names correspond to `type` in `x`
 * @returns The returned value from the corresponding function in `patterns`
 *
 * Example usage:
 * ```
 * type Action =
 *     | {
 *           type: 'ClickJob';
 *           jobId: number;
 *       }
 *     | {
 *           type: 'SubmitForm';
 *           value: string;
 *       };
 *
 * const action: Action = {
 *     type: 'ClickJob',
 *     jobId: 1234
 * };
 *
 * const message = match(action, {
 *     ClickJob: ({ jobId }) => `Job ${jobId} was clicked`,
 *     SubmitForm: ({ value }) => `Form was submitted with ${value}`
 * });
 * ```
 */
export function match<T extends { type: string }, R>(
  x: T,
  patterns: { [P in T['type']]: (x: T extends { type: P } ? T : never) => R },
): R {
  return (patterns as any)[x.type](x);
}
